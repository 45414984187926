import Card from 'react-bootstrap/Card'

import './stripcard.css'

export type StripCardProps = {
    title: string
    image: string
    description: React.ReactNode
    isActive: boolean
    onSelection: (title: string) => void
}

export const StripCard = ({ title, image, description, isActive, onSelection }: StripCardProps) => {
    const selectItem = () => {
        if (isActive) {
            onSelection(title)
        }
    }

    return (
        <Card className={`strip-card${!isActive ? ' strip-card--disabled' : ''}`} onClick={selectItem}>
            <Card.Img variant='top' src={image} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
            </Card.Body>
        </Card>
    )
}
