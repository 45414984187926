import * as React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './wizardnavitem.css'

export type WizardNavItemProps = {
    title: string
    summary?: React.ReactNode
    isActive: boolean
    index: number
}

export const WizardNavItem: React.FC<WizardNavItemProps> = ({ title, summary, isActive, index}) => {
    return (
        <>
            <Row>
                <Col md={3} className='nav-item__numbering-cell'>
                    <span className={`nav-item__number${isActive ? ' nav-item__number--is-active' : ''}`}>
                        {index}
                    </span>
                </Col>
                <Col className='nav-item__title-cell'>
                    <h5 className='nav-item__title'>{title}</h5>
                </Col>
            </Row>
            <Row>
                <Col md={3} className='pt-2 pb-2 nav-item__spacing-cell'>
                    <div className='nav-item__spacing-line' />
                </Col>
                <Col className='pt-1 pb-3'>
                    <div className='m-0 ps-3'>{summary}</div>
                </Col>
            </Row>
        </>
    )
}
