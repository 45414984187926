import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import { calculateVoltageDrop, WireMaterial } from '../helpers/voltageDrop'
import { wireSizes } from '../data'

import './voltagedrop.css'

export const VoltageDrop = () => {
    const [wireMaterial, setWireMaterial] = useState<WireMaterial>('Copper')
    const [length, setLength] = useState(5)
    const [amps, setAmps] = useState(2)

    return (
        <Container>
            <div className='bg-dark p-4'>
                <Row>
                    <Col>
                        <p>This calculator is used to calculate the voltage drop for an LED installation using DC power. Typically, a maximum voltage drop of 0.5-0.6 is recommended in these scenarios.</p>
                    </Col>
                </Row>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3' controlId='material'>
                                <Form.Label>Wire material</Form.Label>
                                <Form.Select value={wireMaterial} onChange={(e) => setWireMaterial(e.target.value as WireMaterial)}>
                                    <option value='Copper'>Copper</option>
                                    <option value='Aluminium'>Aluminium</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='length'>
                                <Form.Label>Wire length</Form.Label>
                                <InputGroup>
                                    <Form.Control type='number' value={length} onChange={(e) => setLength(parseInt(e.target.value))} />
                                    <InputGroup.Text>m</InputGroup.Text>
                                </InputGroup>
                                <Form.Text className='text-muted'>
                                    The length of a single wire from source to destination. Typically a second wire of the same length is used to return back to the source.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='amps'>
                                <Form.Label>Amps transmitted</Form.Label>
                                <InputGroup>
                                    <Form.Control type='number' value={amps} onChange={(e) => setAmps(parseInt(e.target.value))} />
                                    <InputGroup.Text>A</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col>
                        <Table striped={true} bordered={true} className='voltage-drop__table'>
                            <thead>
                                <tr>
                                    <th>AWG</th>
                                    <th>mm²</th>
                                    <th>in²</th>
                                    <th>Voltage Drop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wireSizes.map(wireSize => (
                                    <tr key={wireSize.mm} className='voltage-drop__row'>
                                        <td>{wireSize.gauge}</td>
                                        <td>{wireSize.mm}</td>
                                        <td>{wireSize.in}</td>
                                        <td>{(Math.round(calculateVoltageDrop(length, wireSize.mm / 1000, wireMaterial, amps) * 1000) / 1000).toFixed(3)}V</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
