import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { Visualisation } from './Visualisation'
import { StripConfiguration, StripRequirements } from '../types'
import { toAmps } from '../helpers/powerConversion'
import { findMinimumWireSize, WireMaterial } from '../helpers/voltageDrop'
import { controllers } from '../data'

import './results.css'

export type ResultsProps = {
    stripConfiguration: StripConfiguration
    stripRequirements: StripRequirements
    wireLengths: number[]
    wireMaterial: WireMaterial
    prevStep: () => void
}

export const Results = ({ stripConfiguration, stripRequirements, wireLengths, wireMaterial, prevStep }: ResultsProps) => {
    const requiredWireSizes = wireLengths.map((wireLength, index) => 
        index === 0 ? {wireLength: wireLength, wireSize: findMinimumWireSize(wireLength, wireMaterial, stripRequirements.ampsAtStartOfStrip)}
        : {wireLength: wireLength, wireSize: findMinimumWireSize(wireLength, wireMaterial, stripRequirements.ampsPerMiddleInjectionPoint)})

    const channelsRequired = Math.ceil(stripRequirements.zones / 500)

    let controller = controllers.find(controller => controller.channels >= channelsRequired)

    if (controller === undefined) {
        controller = controllers[1] // use multiple 'quad' boards
    }

    return (
        <>
            <h4>Results</h4>
            <h5 className='results-title'>Installation layout</h5>
            <Visualisation stripRequirements={stripRequirements} />

            <h5 className='results-title'>Power Requirements</h5>
            <p>
                To power your strip, you will need a total of <strong>{stripRequirements.requiredWatts}W</strong> (<strong>{toAmps(stripConfiguration.volts, stripRequirements.requiredWatts)}A</strong>).
                To give yourself a buffer, (as running a PSU at it's rated power can cause overheating issues) we recommend a <strong>{stripRequirements.psuWatts}W</strong> (<strong>{toAmps(stripConfiguration.volts, stripRequirements.psuWatts)}A</strong>) power supply.
            </p>
            <p>For recommendations on which power supply to buy, see <a href='https://quinled.info/power-supplies-digital-leds/#power-supply-style' target='_blank' rel='noreferrer'>this power supply buying guide</a>.</p>

            <h5 className='results-title'>Power Injection</h5>
            { stripRequirements.middleInjectionPoints > 0 ? (
                <>
                    <p>You will need {stripRequirements.middleInjectionPoints + 1} injection points - {stripRequirements.middleInjectionPoints} in the middle and 1 at the start.</p>
                    <p>
                        {stripRequirements.middleInjectionPoints > 0 && `The middle injection points will each inject ${stripRequirements.ampsPerMiddleInjectionPoint}A.`}
                        The start injection point will inject {stripRequirements.ampsAtStartOfStrip}A.
                    </p>
                    <p>The following table shows the minimum size for the wire to each injection point. It is absolutely fine to take the largest size in the table and run all injection points with this size.</p>
                    <Table striped={true} bordered={true}>
                        <thead>
                            <tr>
                                <th>Injection point</th>
                                <th>Strip position</th>
                                <th>Wire length</th>
                                <th>Wire size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requiredWireSizes.map((wireSize, index) => (
                                <tr key={wireSize.wireLength}>
                                    <td>{index === 0 ? 'Start' : index}</td>
                                    <td>{wireSize.wireLength - 1}m</td> { /* dirty dirty hack */}
                                    <td>{wireSize.wireLength}m</td>
                                    <td>{wireSize.wireSize ? `${wireSize.wireSize.mm}mm² / AWG${wireSize.wireSize.gauge} / ${wireSize.wireSize.in}in²` : 'No wire size available'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <p>To inject power (and condition the data signal) we recommend using a <a href='https://quinled.info/quinled-data-booster-for-sale/'>QuinLED-Data-Booster</a>.</p>
                </>
            ) : <p>Not Required!</p>}

            <h5 className='results-title'>Controller configuration</h5>
            <p>A single data line from your controller can control up to 500 individual pixels or zones while maintaining an effect speed at 60FPS - which is ideal.</p>
            <p>
                Your installation contains {stripRequirements.zones} zones or pixels, so we recommend using {Math.ceil(stripRequirements.zones / 500)} individual data lines.{' '}
                {channelsRequired > controller.channels
                    ? <>That's a lot of channels! We recommend {Math.ceil(channelsRequired / controller.channels)} <a href={controller.url} target='_blank' rel='noreferrer'>{controller.name}</a>s</>
                    : <>We recommend a <a href={controller.url} target='_blank' rel='noreferrer'>{controller.name}</a></> }
                {' '} to control your installation.
            </p>
            <p>
                <Button type='button' variant='secondary' onClick={prevStep}>Back</Button>
            </p>
        </>
    )
}
