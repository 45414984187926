import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StepOne } from './stepone/StepOne'
import { StepThree } from './StepThree'
import { DigitalLedForm } from './digitalLeds/DigitalLedForm'
import { WizardNavItem } from './wizard/WizardNavItem'
import { StripConfiguration, StripRequirements } from '../types'
import { calculatePower } from '../helpers/calculation'
import { Results } from './Results'
import { WireMaterial } from '../helpers/voltageDrop'
import { data } from '../data'

export const Installation = () => {
    const [step, setStep] = useState(0)
    const [stripType, setStripType] = useState("")
    const [stripConfiguration, setStripConfiguration] = useState<StripConfiguration>({
        volts: 5,
        chipset: data.find(strip => strip.volts === 5)?.chipset || '',
        density: 30,
        length: 5,
        powerConfiguration: 'standard',
    })
    const [stripRequirements, setStripRequirements] = useState<StripRequirements>()
    const [needsPowerInjection, setNeedsPowerInjection] = useState(false)
    const [wireLengths, setWireLengths] = useState<number[]>([])
    const [wireMaterial, setWireMaterial] = useState<WireMaterial>('Copper')
    const [distances, setDistances] = useState<number[]>([])
    const [initialConfiguration, setInitialConfiguration] = useState(false)

    const goToStep1 = () => setStep(0)
    const goToStep2 = () => setStep(1)
    const goToStep3 = () => setStep(2)
    const goToResults = () => setStep(3)

    const selectStrip = (type: string) => {
        setStripType(type)
        goToStep2()
    }

    const reallySetStripConfiguration = (configuration: StripConfiguration) => {
        setInitialConfiguration(true)
        setStripConfiguration(configuration)
        const calculatedStripRequirements = calculatePower(configuration)
        setStripRequirements(calculatedStripRequirements)

        if (!calculatedStripRequirements) {
            return
        }

        setNeedsPowerInjection(calculatedStripRequirements.middleInjectionPoints > 0)

        if (calculatedStripRequirements.middleInjectionPoints === 0) {
            goToResults()
            return
        }

        if (
            calculatedStripRequirements.ampsAtStartOfStrip === stripRequirements?.ampsAtStartOfStrip &&
            calculatedStripRequirements.ampsPerMiddleInjectionPoint === stripRequirements?.ampsPerMiddleInjectionPoint &&
            calculatedStripRequirements.controllerInputs === stripRequirements.controllerInputs &&
            calculatedStripRequirements.distanceBetweenMiddleInjectionPoints === stripRequirements.distanceBetweenMiddleInjectionPoints &&
            calculatedStripRequirements.middleInjectionPoints === stripRequirements.middleInjectionPoints &&
            calculatedStripRequirements.psuWatts === stripRequirements.psuWatts &&
            calculatedStripRequirements.requiredWatts === stripRequirements.requiredWatts &&
            calculatedStripRequirements.zones === stripRequirements.zones
        ) {
            goToStep3()
            return
        }

        let localDistances: number[] = [0]

        for (let i = 0; i < calculatedStripRequirements.middleInjectionPoints; i++) {
            localDistances.push(Math.round((i + 1) * calculatedStripRequirements.distanceBetweenMiddleInjectionPoints * 100) / 100)
        }

        setDistances(localDistances)
        setWireLengths(localDistances.map(distance => distance + 1))

        goToStep3()
    }

    const setInjectionConfiguration = (configuredWireLengths: number[], configuredWireMaterial: WireMaterial) => {
        setWireLengths(configuredWireLengths)
        setWireMaterial(configuredWireMaterial)

        goToResults()
    }

    return (
        <Container>
            <Row>
                <Col md={3} className='bg-dark pt-5 pb-5'>
                    <WizardNavItem index={1} isActive={step === 0} title='LED Strip Type' summary={stripType} />
                    <WizardNavItem index={2} isActive={step === 1} title='Layout' summary={ stripConfiguration && initialConfiguration && (
                        <ul className='m-0 ps-3'>
                            <li>Volts: {stripConfiguration.volts}</li>
                            <li>Chipset: {stripConfiguration.chipset}</li>
                            <li>Lights/m: {stripConfiguration.density}</li>
                            <li>Length: {stripConfiguration.length}m</li>
                            <li>Power: {stripConfiguration.powerConfiguration}</li>
                        </ul>
                    )} />
                    <WizardNavItem index={3} isActive={step === 2} title='Power Injection' summary={
                        !stripRequirements ? ''
                        : needsPowerInjection ? `Injection points: ${stripRequirements.middleInjectionPoints + 1}`
                        : 'Not required!'
                    } />
                    <WizardNavItem index={4} isActive={step === 3} title='Results' />
                </Col>
                <Col className='bg-body pt-5 pb-5'>
                    {
                        step === 0 ? <StepOne setStripType={selectStrip} /> :
                        step === 1 ? <DigitalLedForm initialStripConfiguration={stripConfiguration} setStripConfiguration={reallySetStripConfiguration} prevStep={goToStep1} /> :
                        step === 2 && stripRequirements ? <StepThree prevStep={goToStep2} setInjectionConfiguration={setInjectionConfiguration} stripRequirements={stripRequirements} stripConfig={stripConfiguration} distances={distances} initialWireLengths={wireLengths} initialWireMaterial={wireMaterial} /> :
                        step === 3 && stripRequirements ? <Results prevStep={stripRequirements.middleInjectionPoints > 0 ? goToStep3 : goToStep2} stripConfiguration={stripConfiguration} stripRequirements={stripRequirements} wireLengths={wireLengths} wireMaterial={wireMaterial} /> :
                        null
                    }
                </Col>
            </Row>
        </Container>
    )
}
