import * as React from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { data } from '../../data'

import './digitalledform.css'
import { StripConfiguration } from '../../types'

type Props = {
    initialStripConfiguration: StripConfiguration
    setStripConfiguration: (configuration: StripConfiguration) => void
    prevStep: () => void
}

export const DigitalLedForm: React.FC<Props> = ({ initialStripConfiguration, setStripConfiguration, prevStep }) => {
    const [volts, setVolts] = useState(initialStripConfiguration.volts)
    const [chipset, setChipset] = useState(initialStripConfiguration.chipset)
    const [density, setDensity] = useState(initialStripConfiguration.density)
    const [length, setLength] = useState(initialStripConfiguration.length)
    const [powerConfiguration, setPowerConfiguration] = useState(initialStripConfiguration.powerConfiguration)

    const supportedChipsets = data.filter(strip => strip.volts === volts)

    function reallySetVolts(volts: number) {
        setVolts(volts)
        setChipset(data.find(strip => strip.volts === volts)?.chipset as string)
    }

    const nextStep = () => {
        setStripConfiguration({
            volts: volts,
            chipset: chipset,
            density: density,
            length: length,
            powerConfiguration: powerConfiguration,
        })
    }

    return (
        <div>
            <h4>Installation details</h4>
            <Form>
                <Form.Group className='mb-3' controlId='volts'>
                    <Form.Label>Volts</Form.Label>
                    <Form.Select value={volts} onChange={(e) => reallySetVolts(parseInt(e.target.value))} className='digital-led-form-field'>
                        <option value='5'>5V</option>
                        <option value='12'>12V</option>
                        <option value='24'>24V</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3' controlId='chipset'>
                    <Form.Label>Chipset</Form.Label>
                    <Form.Select value={chipset} onChange={e => setChipset(e.target.value)} className='digital-led-form-field'>
                        {supportedChipsets.map(strip => (
                            <option key={strip.chipset} value={strip.chipset}>{strip.chipset}</option>
                        ))}
                    </Form.Select>
                    <Form.Text className='text-muted'>
                        You can find this on the packaging of your strip, or your strip itself. If you haven't bought a strip yet, see <a href='https://quinled.info/2019/06/03/what-digital-5v-12v-rgbw-led-strip-to-buy/'>this LED strip buying guide</a>.
                    </Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='density'>
                    <Form.Label>LEDs per meter</Form.Label>
                    <Form.Control type='number' value={density} onChange={e => setDensity(parseInt(e.target.value))} className='digital-led-form-field' />
                    <Form.Text className='text-muted'>
                        How many LEDs in a single meter of your strip.
                    </Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='length'>
                    <Form.Label>Installation Length</Form.Label>
                    <InputGroup className='digital-led-form-field'>
                        <Form.Control value={length} type='number' onChange={(e) => setLength(parseInt(e.target.value))} />
                        <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                    <Form.Text className='text-muted'>
                        The total length of the LED strip you will use for this installation.
                    </Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='volts'>
                    <Form.Label>Power configuration</Form.Label>
                    <Form.Select value={powerConfiguration} onChange={e => setPowerConfiguration(e.target.value)} className='digital-led-form-field'>
                        <option value='standard'>Standard</option>
                        <option value='maximum'>Maximum</option>
                    </Form.Select>
                    <Form.Text className='text-muted'>
                        <strong>Standard</strong>: Full effects, 1-2 colours at full brightness, white channel at full brightness (if present).<br />
                        <strong>Maximum</strong>: All colours (including white) at full brightness. In most cases this is more power than you will ever need.
                    </Form.Text>
                </Form.Group>
            </Form>
            <Row>
                <Col xs='auto'>
                    <Button variant='secondary' onClick={prevStep} type='button'>Back</Button>
                </Col>
                <Col xs='auto'>
                    <Button onClick={nextStep} type='button'>Next</Button>
                </Col>
            </Row>
        </div>
    )
}
