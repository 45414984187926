import { wireSizes } from "../data"

export type WireMaterial = 'Copper' | 'Aluminium'

const materialResistivities: {[key in WireMaterial]: number} = {
    'Copper': 1.72e-8,
    'Aluminium': 2.82e-8,
}

// all distance inputs must be in the same measurement
export const calculateVoltageDrop = (lengthOfWire: number, wireDiameter: number, wireMaterial: WireMaterial, amps: number) => {
    const areaOfWire = Math.PI * wireDiameter * wireDiameter / 4
    const wireResistivity = 2 * materialResistivities[wireMaterial] * lengthOfWire / areaOfWire

    return amps * wireResistivity
}

export const findMinimumWireSize = (lengthOfWire: number, wireMaterial: WireMaterial, amps: number) => {
    return wireSizes.find( wireSize => calculateVoltageDrop(lengthOfWire, wireSize.mm / 1000, wireMaterial, amps) < 0.6)
}
