import * as React from 'react'
import { StripCard } from './StripCard'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import digitalstrip from '../../images/digitalstrip.png'
import ledpixels from '../../images/ledpixels.png'
import analogstrip from '../../images/analogstrip.png'

type Props = {
    setStripType: (type: string) => void
}

export const StepOne: React.FC<Props> = ({ setStripType }) => {
    return (
        <div>
            <h4>What sort of LED Strip are you going to use?</h4>
            <p>The most common and versatile option is the Digital LED Strip. If you're unsure, this is a great place to start.</p>
            <div>
                <Row>
                    <Col>
                        <StripCard
                            title='Digital LED Strip'
                            description='A standard digital LED Strip, where individual LEDs (or groups of them) can be controlled individually'
                            image={digitalstrip}
                            onSelection={setStripType}
                            isActive={true}
                        />
                    </Col>
                    <Col>
                        <StripCard
                            title='Digital LED Pixel String'
                            description='A set of individual LED Pixels, connected by thin wire strings'
                            image={ledpixels}
                            onSelection={setStripType}
                            isActive={false}
                        />
                    </Col>
                    <Col>
                        <StripCard
                            title='Analog LED Strip'
                            description='An LED strip where every LED is the same colour. Can be either a single colour (typically white) or RGB'
                            image={analogstrip}
                            onSelection={setStripType}
                            isActive={false}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
