export type StripConfig = {
    volts: 5 | 12 | 24
    chipset: string
    maximumPower: number
    standardPower: number
    ledsPerZone: number
}

const data: StripConfig[] = [
    // 5V
    {
        volts: 5,
        chipset: 'WS2812b',
        maximumPower: 65,
        standardPower: 35,
        ledsPerZone: 1,
    }, {
        volts: 5,
        chipset: 'WS2812b-Eco',
        maximumPower: 47,
        standardPower: 25,
        ledsPerZone: 1,
    }, {
        volts: 5,
        chipset: 'SK6812',
        maximumPower: 77,
        standardPower: 36,
        ledsPerZone: 1,
    }, {
        volts: 5,
        chipset: 'APA102',
        maximumPower: 88,
        standardPower: 45,
        ledsPerZone: 1,
    },
    // 12V
    {
        volts: 12,
        chipset: 'WS2815',
        maximumPower: 50,
        standardPower: 27,
        ledsPerZone: 1,
    }, {
        volts: 12,
        chipset: 'BTF2815 (ucs1903)',
        maximumPower: 47,
        standardPower: 29,
        ledsPerZone: 1,
    }, {
        volts: 12,
        chipset: 'GS8208',
        maximumPower: 63,
        standardPower: 28,
        ledsPerZone: 1,
    }, {
        volts: 12,
        chipset: 'GS8208',
        maximumPower: 63,
        standardPower: 29,
        ledsPerZone: 1,
    }, {
        volts: 12,
        chipset: 'WS2811 (normal)',
        maximumPower: 75,
        standardPower: 40,
        ledsPerZone: 3,
    }, {
        volts: 12,
        chipset: 'WS2811 (high-power/bright)',
        maximumPower: 80,
        standardPower: 45,
        ledsPerZone: 3,
    }, {
        volts: 12,
        chipset: 'SK6812',
        maximumPower: 68,
        standardPower: 25,
        ledsPerZone: 3,
    },
    // 24V
    {
        volts: 24,
        chipset: 'WS2811 (double wide)',
        maximumPower: 118,
        standardPower: 60,
        ledsPerZone: 3,
    }, {
        volts: 24,
        chipset: 'SK6812',
        maximumPower: 59,
        standardPower: 30,
        ledsPerZone: 6,
    }, {
        volts: 24,
        chipset: 'TM1814',
        maximumPower: 116,
        standardPower: 45,
        ledsPerZone: 6,
    }
]

export type Controller = {
    name: string
    url: string
    channels: number
}

const controllers = [
    {
        name: 'QuinLED-Dig-Uno',
        url: 'https://quinled.info/quinled-dig-uno/',
        channels: 2,
    }, {
        name: 'QuinLED-Dig-Quad',
        url: 'https://quinled.info/quinled-dig-quad/',
        channels: 4,
    }, {
        name: 'QuinLED-Dig-Octa System',
        url: 'https://quinled.info/quinled-dig-octa/',
        channels: 8,
    },
]

const psus = [ // amps
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    10,
    15,
    20,
    30,
    40,
    50,
    60,
]

export type WireSize = {
    gauge: string
    in: number
    mm: number
}

const wireSizes: WireSize[] = [
    { gauge: '0000 (4/0)', in: 0.4600, mm: 11.6840 },
    { gauge: '000 (3/0)', in: 0.4096, mm: 10.4049 },
    { gauge: '00 (2/0)', in: 0.3648, mm: 9.2658 },
    { gauge: '0 (1/0)', in: 0.3249, mm: 8.2515 },
    // { gauge: '1', in: 0.2893, mm: 7.3481 },
    { gauge: '2', in: 0.2576, mm: 6.5437 },
    // { gauge: '3', in: 0.2294, mm: 5.8273 },
    { gauge: '4', in: 0.2043, mm: 5.1894 },
    // { gauge: '5', in: 0.1819, mm: 4.6213 },
    { gauge: '6', in: 0.1620, mm: 4.1154 },
    // { gauge: '7', in: 0.1443, mm: 3.6649 },
    { gauge: '8', in: 0.1285, mm: 3.2636 },
    // { gauge: '9', in: 0.1144, mm: 2.9064 },
    { gauge: '10', in: 0.1019, mm: 2.5882 },
    // { gauge: '11', in: 0.0907, mm: 2.3048 },
    { gauge: '12', in: 0.0808, mm: 2.0525 },
    // { gauge: '13', in: 0.0720, mm: 1.8278 },
    { gauge: '14', in: 0.0641, mm: 1.6277 },
    // { gauge: '15', in: 0.0571, mm: 1.4495 },
    { gauge: '16', in: 0.0508, mm: 1.2908 },
    // { gauge: '17', in: 0.0453, mm: 1.1495 },
    { gauge: '18', in: 0.0403, mm: 1.0237 },
    // { gauge: '19', in: 0.0359, mm: 0.9116 },
    { gauge: '20', in: 0.0320, mm: 0.8118 },
    // { gauge: '21', in: 0.0285, mm: 0.7229 },
    { gauge: '22', in: 0.0253, mm: 0.6438 },
    // { gauge: '23', in: 0.0226, mm: 0.5733 },
    { gauge: '24', in: 0.0201, mm: 0.5106 },
    // { gauge: '25', in: 0.0179, mm: 0.4547 },
    { gauge: '26', in: 0.0159, mm: 0.4049 },
    // { gauge: '27', in: 0.0142, mm: 0.3606 },
    { gauge: '28', in: 0.0126, mm: 0.3211 },
    // { gauge: '29', in: 0.0113, mm: 0.2859 },
    { gauge: '30', in: 0.0100, mm: 0.2546 },
    // { gauge: '31', in: 0.0089, mm: 0.2268 },
    { gauge: '32', in: 0.0080, mm: 0.2019 },
    // { gauge: '33', in: 0.0071, mm: 0.1798 },
    { gauge: '34', in: 0.0063, mm: 0.1601 },
    // { gauge: '35', in: 0.0056, mm: 0.1426 },
    { gauge: '36', in: 0.0050, mm: 0.1270 },
    // { gauge: '37', in: 0.0045, mm: 0.1131 },
    { gauge: '38', in: 0.0040, mm: 0.1007 },
    // { gauge: '39', in: 0.0035, mm: 0.0897 },
    { gauge: '40', in: 0.0031, mm: 0.0799 },
].reverse()

export {
    data,
    controllers,
    psus,
    wireSizes,
}
