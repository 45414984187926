import { useEffect, useRef } from 'react'
import { StripRequirements } from '../types'

import ledStrip from '../images/LED Strip.png'
import supplyandcontroller from '../images/supplyandcontroller.png'
import injector from '../images/injector.png'

import './visualisation.css'

export type VisualisationProps = {
    stripRequirements: StripRequirements
}

export const Visualisation = ({ stripRequirements }: VisualisationProps) => {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        if (canvas === null) {
            return
        }

        const context = (canvas as HTMLCanvasElement).getContext('2d')

        if (context === null) {
            return
        }

        // clear out old drawing
        context.clearRect(0, 0, 3000, 3000)

        // put in LED strip
        const LEDStrip = new Image()
        LEDStrip.onload = () => {
            context.drawImage(LEDStrip, 500, 123, 1606, 54)
            context.drawImage(LEDStrip, 2106, 123, 1606, 54)

            let drawnLines = 0

            if (stripRequirements.middleInjectionPoints > 0) {
                // 1296
                // 3000 - 2106 = 894
                // 500 is start of LED strip
                // 3000 is end of LED strip
                // LED strip is 2500 long
                // injector is 250 wide
                // 2250 to share across all injectors
    
                const injectionPointWidth = 75
                const LEDWidth = 2500
                const injectionPointsToInsert = stripRequirements.middleInjectionPoints
                const totalInjectionPointSpace = injectionPointsToInsert * injectionPointWidth
                const spaceBetweenInjectionPoints = (LEDWidth - injectionPointWidth - totalInjectionPointSpace) / (injectionPointsToInsert + 1)
    
                let insertionPoint = spaceBetweenInjectionPoints + 500 // start of LED strip + calculated space between
    
                const injectorImage = new Image()
                injectorImage.onload = () => {
                    // put in injectors if necessary
                    for (let i = 0; i < injectionPointsToInsert; i++ ) {
                        // draw power injector
                        context.drawImage(injectorImage, insertionPoint, 113, 75, 75)

                        // draw red power wire
                        context.beginPath()
                        context.moveTo(300 - ((i+1) * 10), 123)
                        context.lineTo(300 - ((i+1) * 10), 123 - ((i+1) * 20))
                        context.lineTo(insertionPoint + (injectionPointWidth / 2), 123 - ((i+1) * 20))
                        context.lineTo(insertionPoint + (injectionPointWidth / 2), 123)
                        context.strokeStyle = '#e80000'
                        context.lineWidth = 5
                        context.stroke()

                        // draw black power wire
                        context.beginPath()
                        context.moveTo(300 - ((i+1) * 10), 123)
                        context.lineTo(300 - ((i+1) * 10), 177 + ((i+1) * 20))
                        context.lineTo(insertionPoint + (injectionPointWidth / 2), 177 + ((i+1) * 20))
                        context.lineTo(insertionPoint + (injectionPointWidth / 2), 177)
                        context.strokeStyle = '#ced4da'
                        context.lineWidth = 5
                        context.stroke()

                        insertionPoint = insertionPoint + spaceBetweenInjectionPoints + injectionPointWidth
                        drawnLines = i
                    }

                    drawnLines = drawnLines + 1
                }
                injectorImage.src = injector
            }

            // put in supply image
            const supplyAndControllerImage = new Image()
            supplyAndControllerImage.onload = () => {
                context.drawImage(supplyAndControllerImage, 0, 0, 436, 308)
            }
            supplyAndControllerImage.src = supplyandcontroller
        }
        LEDStrip.src = ledStrip

        // red wire to LED
        context.beginPath()
        context.moveTo(300, 123)
        context.lineTo(500, 123)
        context.strokeStyle = '#e80000'
        context.lineWidth = 5
        context.stroke()

        // green wire to LED
        context.beginPath()
        context.moveTo(300, 150)
        context.lineTo(500, 150)
        context.strokeStyle = '#1abc0a'
        context.lineWidth = 5
        context.stroke()

        // black wire to LED
        context.beginPath()
        context.moveTo(300, 177)
        context.lineTo(500, 177)
        context.strokeStyle = '#ced4da'
        context.lineWidth = 5
        context.stroke()

    }, [stripRequirements])

    return (
        <div className='visualisation'>
            <canvas ref={canvasRef} width='3000' height='500' className='visualisation-canvas' />
        </div>
    )
}
