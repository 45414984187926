import { StripRequirements, StripConfiguration } from '../types'
import { data, psus } from '../data'
import { toAmps, toWatts } from './powerConversion'

export const calculatePower = (stripConfig: StripConfiguration): StripRequirements | undefined => {
    const totalLEDs = stripConfig.length * stripConfig.density
    const stripDetails = data.find(strip => strip.volts === stripConfig.volts && strip.chipset === stripConfig.chipset)

    if (stripDetails === undefined) {
        return
    }

    // How much power? Datasheet power divided by 300 LEDs of the datasheet value, multiplied by the number of LEDs in the installation
    const calculatedPower = Math.ceil((stripConfig.powerConfiguration === 'standard' ? stripDetails.standardPower : stripDetails.maximumPower) / 300 * totalLEDs)

    const calculatedAmps = toAmps(stripConfig.volts, calculatedPower)

    // find a PSU by adding a 10% safety margin and rounding up to the next common PSU value
    const safeAmps = calculatedAmps * 1.1
    const foundPsuAmps = psus.find(psu => psu >= safeAmps) || 0

    // inject a max of 4 amps per injection point - calculating 8 here as a middle injection point can send power both ways
    const calculatedInjectionPoints = Math.ceil((calculatedAmps - 4) / 8)
    const ampsPerMiddleInjectionPoint = calculatedAmps / (calculatedInjectionPoints + .5)
    const ampsPerEndInjectionPoint = ampsPerMiddleInjectionPoint / 2
    const middleInjectionPoints = calculatedInjectionPoints

    // calculate distance between middle injection points
    const distanceBetweenMiddleInjectionPoints = stripConfig.length / (middleInjectionPoints + 1)

    // calculate required controller inputs
    const zones = totalLEDs / stripDetails.ledsPerZone
    const controllerInputs = Math.ceil(zones / 500)

    return {
        requiredWatts: calculatedPower,
        middleInjectionPoints: middleInjectionPoints,
        distanceBetweenMiddleInjectionPoints: distanceBetweenMiddleInjectionPoints,
        ampsPerMiddleInjectionPoint: ampsPerMiddleInjectionPoint,
        ampsAtStartOfStrip: ampsPerEndInjectionPoint,
        psuWatts: toWatts(stripConfig.volts, foundPsuAmps),
        zones: zones,
        controllerInputs: controllerInputs,
    }
}
