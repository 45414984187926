import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion';
import { NavLink, Outlet } from 'react-router-dom'

export const App = () => {
    return (
        <>
            <Navbar bg='dark' className='navbar-dark mb-5'>
                <Container>
                    <Navbar.Brand>LED Calculators</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link to="/" as={NavLink}>Full Installation</Nav.Link>
                            <Nav.Link to="voltage-drop" as={NavLink}>Voltage Drop</Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
            <Container className='mt-5'>
                <Row>
                    <Col md={12} lg={6} xl={4}>
                        <Accordion>
                            <Accordion.Item eventKey="disclaimer">
                                <Accordion.Header>Disclaimer</Accordion.Header>
                                <Accordion.Body>
                                    <p>The numbers generated by this calculator are a guide only. We strongly recommend doing your own calculations before making any purchasing decisions.</p>
                                    <p>When working with electronics, there's always the danger of electrocution and fire. If in doubt, refer to a licensed professional.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="attribution">
                                <Accordion.Header>Attribution</Accordion.Header>
                                <Accordion.Body>
                                    <p>The "magic numbers" required to power this calculator come from the excelent work at <a href="https://quinled.info/2020/03/12/digital-led-power-usage/" target="_blank" rel="noreferrer">Quinled.info</a>.</p>
                                    <p>LED image Image by upklyak on Freepik</p>
                                    <p>Controller Image by macrovector on Freepik</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
