import * as React from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StripConfiguration, StripRequirements } from '../types'
import { Visualisation } from './Visualisation'
import { WireMaterial } from '../helpers/voltageDrop'

import './powerinjection.css'

type Props = {
    initialWireMaterial: WireMaterial
    initialWireLengths: number[]
    distances: number[]
    stripRequirements: StripRequirements
    stripConfig: StripConfiguration
    prevStep: () => void
    setInjectionConfiguration: (wireLengths: number[], wireMaterial: WireMaterial) => void
}

export const StepThree: React.FC<Props> = ({ initialWireMaterial, initialWireLengths, distances, stripRequirements, stripConfig, prevStep, setInjectionConfiguration }) => {
    const [wireMaterial, setWireMaterial] = useState<WireMaterial>(initialWireMaterial)
    const [wireLengths, setWireLengths] = useState<number[]>(initialWireLengths)

    const setWireLength = (length: number, index: number) => {
        const newWireLengths = [...wireLengths]
        newWireLengths[index] = length
        setWireLengths(newWireLengths)
    }

    return (
        <div>
            <h4>Power Injection</h4>
            <p>Voltage will drop over the length of an LED strip. If not addressed, this will cause noticable dimness in LEDs towards the end of the strip, and may cause LEDs to not turn on at all.</p>
            <p>To address this, it's required to inject extra power at strategic positions along your strip. The following visualisation shows optimal placement of power injection points in your strip:</p>

            <Visualisation stripRequirements={stripRequirements} />

            <h5>Distance to injection points</h5>
            <p>Voltage drops over the distance of a wire, not just the strip itself. To ensure the voltage delivered at each injection point is optimal, the right thickness of wire is required. To calculate this, we need to know how long these wires are going to be.</p>
            <p>The default numbers in the table below are based on the position in the strip of the injection point with a bit of buffer added. Once you've configured this based on your individual setup, hit next to see the final requirements of the installation.</p>

            <Table striped={true} bordered={true} className='injection-table'>
                <thead>
                    <tr>
                        <th>Injection point</th>
                        <th>Strip position</th>
                        <th>Wire length</th>
                    </tr>
                </thead>
                <tbody>
                    {distances.map((distance, index) => (
                        <tr key={distance}>
                            <td>{index === 0 ? 'Start' : index}</td>
                            <td>{distance}m</td>
                            <td>
                                <InputGroup className='injection-table__group'>
                                    <Form.Control type='number' value={wireLengths[index]} onChange={e => setWireLength(parseInt(e.target.value), index)} name={`injection-point-${index}`} />
                                    <InputGroup.Text>m</InputGroup.Text>
                                </InputGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Row>
                <Col sm='4'>
                    <Form.Group className='mb-3' controlId='volts'>
                        <Form.Label>Wire material</Form.Label>
                        <Form.Select value={wireMaterial} onChange={(e) => setWireMaterial(e.target.value as WireMaterial)}>
                            <option value='Copper'>Copper</option>
                            <option value='Aluminium'>Aluminium</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs='auto'>
                    <Button variant='secondary' onClick={prevStep} type='button'>Back</Button>
                </Col>
                <Col xs='auto'>
                    <Button onClick={e => setInjectionConfiguration(wireLengths, wireMaterial)} type='button'>Next</Button>
                </Col>
            </Row>
        </div>
    )
}
